.ar-success-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 1rem;
}

.ar-success-card {
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
  max-width: 400px;
  width: 100%;
  text-align: center;
}

.ar-success-icon {
  background-color: #ecfdf5;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto 1.5rem auto;
}

.ar-success-icon svg {
  width: 24px;
  height: 24px;
  color: #059669;
}

.ar-success-title {
  color: #111827;
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ar-success-message {
  color: #6b7280;
  margin-bottom: 1.5rem;
  line-height: 1.5;
}

.ar-close-button {
  background-color: #0066cc;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0.75rem 1.5rem;
  width: 100%;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ar-close-button:hover {
  background-color: #0052a3;
}

.ar-close-button:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  box-shadow: 0 0 0 2px white, 0 0 0 4px #0066cc;
}