.email-settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.email-settings h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.email-settings-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group h3 {
  margin: 0;
  font-size: 16px;
  color: #333;
  font-weight: 600;
}

.form-group label {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
}

.form-group input[type="checkbox"] {
  width: 16px;
  height: 16px;
  cursor: pointer;
}

.reminder-setting {
  display: flex;
  align-items: center;
  gap: 10px;
}

.reminder-setting select {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
}

.form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #eee;
}

.btn-save,
.btn-cancel {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.btn-save {
  background-color: #3498db;
  color: white;
}

.btn-save:hover {
  background-color: #2980b9;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
}

.btn-cancel:hover {
  background-color: #d32f2f;
}

.ar-recipients {
  margin-top: 10px;
}

/* Email Recipient Input Styles */
.email-input-container {
  min-height: 42px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: white;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  transition: all 0.2s;
}

.email-input-container:focus-within {
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.1);
}

.email-input-container.invalid {
  border-color: #f44336;
}

.email-input-container.disabled {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.email-chip {
  display: inline-flex;
  align-items: center;
  height: 28px;
  padding: 0 8px;
  background-color: #E8F5E9;
  border: 1px solid #C8E6C9;
  border-radius: 4px;
  font-size: 14px;
  color: #2E7D32;
  gap: 6px;
  transition: background-color 0.2s;
  max-width: 250px;
}

.email-chip:hover:not(.disabled) {
  background-color: #C8E6C9;
}

.email-chip.disabled {
  opacity: 0.5;
}

.email-chip-text {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.remove-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 16px;
  height: 16px;
  padding: 0;
  border: none;
  background: transparent;
  color: #2E7D32;
  cursor: pointer;
  border-radius: 50%;
  transition: background-color 0.2s;
}

.remove-button:hover:not(:disabled) {
  background-color: #A5D6A7;
}

.remove-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(76, 175, 80, 0.4);
}

.remove-button:disabled {
  cursor: not-allowed;
}

.email-input {
  flex: 1;
  min-width: 120px;
  height: 28px;
  border: none;
  outline: none;
  font-size: 14px;
  background: transparent;
}

.email-input::placeholder {
  color: #757575;
}

.email-input:disabled {
  background: transparent;
  cursor: not-allowed;
}