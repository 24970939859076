.registration-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.registration-container {
  display: flex;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 800px;
  max-width: 90%;
}

.logo-container {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  padding: 20px;
}

.logo {
  max-width: 100%;
  height: auto;
}

.form-container {
  flex: 1;
  padding: 40px;
}

.registration-page h2 {
  margin-bottom: 20px;
  color: #333;
  font-size: 24px;
}

.registration-page .form-group {
  margin-bottom: 20px;
}

.registration-page label {
  display: block;
  margin-bottom: 5px;
  color: #666;
}

.registration-page .form-input {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
  background-color: #e8f0fe;
}

.registration-page .register-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #4285f4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.registration-page .register-button:hover {
  background-color: #3367d6;
}

.registration-page .register-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.registration-page .error-message {
  color: red;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .registration-container {
    flex-direction: column;
  }
  
  .logo-container, .form-container {
    width: 100%;
  }
}