.calendar {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 20px;
}

/* Month View styles */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #ddd;
}

.calendar-day-label {
  background-color: #f0f0f0;
  padding: 5px;
  text-align: center;
  font-weight: bold;
  border-bottom: 1px solid #ddd;
}

.calendar-day {
  background-color: white;
  min-height: 80px;
  padding: 5px;
  cursor: pointer;
  position: relative;
  border: 1px solid #ddd;
  aspect-ratio: 1 / 1;
  display: flex;
  flex-direction: column;
}

.date-header {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  min-height: 25px;
  position: relative;
  margin-bottom: 2px;
}

.day-number {
  position: relative;
  font-weight: 500;
  margin-right: 5px;
}

/* Week View styles */
.week-view {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
  background-color: #ddd;
}

.week-day {
  background-color: white;
  min-height: 150px;
  padding: 5px;
  border: 1px solid #ddd;
}

.day-header {
  font-weight: bold;
  text-align: center;
  padding: 5px;
  background-color: #f0f0f0;
  border-bottom: 1px solid #ddd;
  position: relative;
}

.day-content {
  height: calc(100% - 30px);
  overflow-y: auto;
}

/* Day View styles */
.day-view {
  width: 100%;
  background-color: white;
  border: 1px solid #ddd;
  padding: 10px;
  min-height: 1440px; /* 24 hours * 60 minutes */
}

.day-timeline {
  display: flex;
  flex-direction: column;
}

.timeline-hour {
  display: flex;
  height: 60px;
  border-top: 1px solid #ddd;
}

.hour-label {
  width: 60px;
  text-align: right;
  padding-right: 10px;
  font-size: 0.8em;
  color: #888;
}

.hour-content {
  flex-grow: 1;
  position: relative;
}

/* Holiday styles */
.holiday-indicator {
  position: relative;
  font-size: 0.8em;
  color: #e74c3c;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.holiday-date {
  background-color: rgba(231, 76, 60, 0.1);
}

.today {
  background-color: rgba(52, 152, 219, 0.1);
  border: 2px solid #3498db !important;
}

/* Calendar Header */
.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding: 15px 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.calendar-header h2 {
  margin: 0;
  font-size: 1.5rem;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

/* Project styles */
.has-projects {
  background-color: rgba(230, 247, 255, 0.5);
}

.project-list {
  flex: 1;
  overflow-y: auto;
  margin-top: 2px;
}

.project-item {
  background-color: #e6f7ff;
  margin: 2px 0;
  padding: 2px 5px;
  font-size: 0.9em;
  cursor: pointer;
  border: 1px solid #b3e0ff;
  border-radius: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  transition: transform 0.2s ease;
  position: relative;
  z-index: 1;
}

.project-item:hover {
  transform: translateY(-1px);
  z-index: 2;
}

.project-item-expanded {
  padding: 12px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  position: relative;
  z-index: 1;
}

.project-item-expanded:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  z-index: 2;
}

.project-item-expanded .project-time {
  font-size: 0.85em;
  color: #666;
  margin-top: 4px;
}

.project-details {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.project-details p {
  margin: 0;
  line-height: 1.4;
}

.project-details strong {
  color: #2c3e50;
  margin-right: 8px;
}

.project-time {
  color: #666;
  font-size: 0.9em;
  margin-top: 8px;
}

.more-projects {
  font-size: 0.8em;
  color: #1890ff;
  cursor: pointer;
  margin-top: 2px;
  background-color: rgba(24, 144, 255, 0.1);
  padding: 4px 8px;
  border-radius: 3px;
  text-align: center;
  transition: all 0.2s ease;
}

.more-projects:hover {
  background-color: rgba(24, 144, 255, 0.2);
  transform: translateY(-1px);
}

/* Project Popup */
.project-popup {
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  max-width: 300px;
  z-index: 1000;
  word-wrap: break-word;
  font-size: 12px;
  line-height: 1.4;
  pointer-events: all;
  transition: opacity 0.2s ease-in-out;
}

.project-popup h4 {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 14px;
  color: #2c3e50;
}

.project-popup p {
  margin: 6px 0;
}

/* Day Projects Modal */
.day-projects-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1100;
  backdrop-filter: blur(4px);
}

.day-projects-modal {
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
}

.day-projects-modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  padding-bottom: 12px;
  border-bottom: 1px solid #eee;
}

.day-projects-modal-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.day-projects-modal-close {
  background: none;
  border: none;
  font-size: 24px;
  color: #666;
  cursor: pointer;
  padding: 4px;
  line-height: 1;
  transition: all 0.2s ease;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.day-projects-modal-close:hover {
  color: #333;
  background-color: #f0f0f0;
}

.day-projects-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .calendar-day {
    min-height: 70px;
  }
  
  .day-projects-modal {
    width: 95%;
    margin: 20px;
  }
  
  .holiday-indicator {
    font-size: 0.7em;
  }
  
  .calendar-header {
    padding: 10px 15px;
  }
}

@media (max-width: 768px) {
  .calendar-day {
    min-height: 60px;
  }
  
  .week-day {
    min-height: 120px;
  }
  
  .day-projects-modal {
    width: 100%;
    height: 100%;
    max-height: none;
    border-radius: 0;
    margin: 0;
  }

  .calendar-header {
    flex-direction: column;
    gap: 15px;
    text-align: center;
  }
  
  .calendar-header h2 {
    font-size: 1.2rem;
  }
}