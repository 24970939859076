.dashboard {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.app-header {
  padding: 10px 20px;
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px; /* Fixed height for the header */
}

.logo-container {
  width: 150px; /* Reduced width */
}

.logo {
  max-width: 100%;
  height: auto;
  max-height: 60px; /* Limit the logo height */
}

.header-controls {
  display: flex;
  gap: 10px;
}

.dashboard-content {
  display: flex;
  flex: 1;
  overflow: hidden;
}

.sidebar {
  width: 200px; /* Slightly reduced width */
  background-color: #f0f0f0;
  padding: 10px;
  height: calc(100vh - 80px); /* Subtract header height */
  overflow-y: auto;
}

.main-content {
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.calendar-container {
  flex: 1;
  overflow-y: auto;
  padding: 20px;
}

/* Ensure the calendar takes up the available space */
.calendar {
  height: 100%;
  min-height: 600px; /* Adjust this value as needed */
}

/* Adjust the calendar grid */
.calendar-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
  gap: 1px;
}

.calendar-day {
  aspect-ratio: 1 / 1; /* Make cells square */
  border: 1px solid #ddd;
  padding: 5px;
  font-size: 0.8em; /* Reduce font size if needed */
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .logo-container {
    width: 120px;
  }
  
  .sidebar {
    width: 180px;
  }
}

@media (max-width: 768px) {
  .app-header {
    flex-direction: column;
    height: auto;
  }
  
  .dashboard-content {
    flex-direction: column;
  }
  
  .sidebar {
    width: 100%;
    height: auto;
  }
  
  .main-content {
    height: 0;
    flex: 1;
  }
}