.settings {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
}

.settings h1 {
  text-align: center;
  margin-bottom: 20px;
}

.settings-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.back-button {
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 20px;
}

.tabs {
  display: flex;
}

.tabs button {
  padding: 10px 15px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 10px;
}

.tabs button:last-child {
  margin-right: 0;
}

.tabs button:hover {
  background-color: #2980b9;
}

.tabs button.active {
  background-color: #2980b9;
  font-weight: bold;
}

.error-message {
  color: red;
  margin-bottom: 10px;
}

.add-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 20px;
}

.add-form input[type="text"],
.add-form input[type="email"],
.add-form input[type="tel"] {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.add-form button[type="submit"] {
  padding: 10px;
  font-size: 16px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.add-form button[type="submit"]:hover {
  background-color: #2980b9;
}

.item-list, .branch-list {
  list-style-type: none;
  padding: 0;
}

.item-list li, .branch-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
}

.item-list li:last-child, .branch-item:last-child {
  border-bottom: none;
}

.branch-name {
  font-weight: bold;
}

.branch-actions {
  display: flex;
  gap: 10px;
}

.select-btn, .delete-btn {
  padding: 5px 10px;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.select-btn {
  background-color: #3498db;
}

.select-btn:hover {
  background-color: #2980b9;
}

.delete-btn {
  background-color: #f44336;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

.confirm-delete {
  background-color: #f8d7da;
  border: 1px solid #f5c6cb;
  border-radius: 4px;
  padding: 15px;
  margin-top: 20px;
}

.confirm-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  gap: 10px;
}

.cancel-btn {
  background-color: #6c757d;
  color: white;
  border: none;
  padding: 5px 10px;
  border-radius: 4px;
  cursor: pointer;
}

.cancel-btn:hover {
  background-color: #5a6268;
}