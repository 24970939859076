.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: 1000;
  overflow-y: auto;
  padding: 2rem 0;
}

.modal-content {
  position: relative;
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  margin: auto;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-content h2 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 20px;
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
  padding: 10px 0;
}

.project-form {
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.form-group label {
  margin-bottom: 5px;
  font-weight: 500;
}

.form-group input[type="text"],
.form-group input[type="datetime-local"],
.form-group input[type="email"],
.form-group textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
}

.form-group textarea {
  min-height: 100px;
  resize: vertical;
}

.form-group h3 {
  margin-bottom: 10px;
}

.form-group label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.form-group input[type="checkbox"] {
  margin-right: 5px;
}

.form-actions {
  position: sticky;
  bottom: 0;
  background: white;
  padding: 1rem 0;
  margin-top: auto;
  border-top: 1px solid #e5e5e5;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
  z-index: 1;
}

.form-actions button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
  height: 36px;
  line-height: 1;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 100px;
  margin: 0;
  vertical-align: middle;
}

.btn-save {
  background-color: #3498db;
  color: white;
}

.btn-cancel {
  background-color: #f44336;
  color: white;
}

.btn-delete {
  background-color: #ff9800;
  color: white;
}

.form-actions button:hover {
  opacity: 0.9;
}

.payment-section .payment-container {
  display: flex;
  justify-content: start;
  align-items: center;
  gap: 20px;
  padding: 15px;
  background-color: #f8f9fa;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  margin-bottom: 15px;
}

.payment-section .cod-container,
.payment-section .amount-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.payment-section .amount-container {
  flex-grow: 1;
}

.payment-section .amount-container label {
  margin-bottom: 0;
  white-space: nowrap;
}

.payment-section .amount-due-input {
  width: 120px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  text-align: right;
  background-color: white;
}

.form-group select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  background-color: white;
}

/* Checkbox styles */
.form-group label input[type="checkbox"] {
  margin-right: 8px;
  width: 16px;
  height: 16px;
}

/* Focus styles */
.form-group input:focus,
.form-group textarea:focus,
.form-group select:focus,
.payment-section .amount-due-input:focus {
  outline: none;
  border-color: #3498db;
  box-shadow: 0 0 0 2px rgba(52, 152, 219, 0.2);
}

/* Error state */
.form-group .error {
  color: #f44336;
  font-size: 12px;
  margin-top: 4px;
}

.form-group input.error,
.form-group textarea.error,
.form-group select.error {
  border-color: #f44336;
}

/* Loading state */
.form-actions button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
  opacity: 0.7;
}

/* Error banner */
.error-banner {
  background-color: #ffebee;
  color: #f44336;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
  position: sticky;
  top: 50px;
  z-index: 1;
}

.payment-info {
  background-color: #f8f9fa;
  padding: 15px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
}

.payment-info p {
  margin: 5px 0;
}

.payment-info .note {
  font-size: 0.9em;
  color: #666;
  margin-top: 10px;
  font-style: italic;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    width: 95%;
    margin: 10px;
  }

  .payment-section .payment-container {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .payment-section .amount-container {
    width: 100%;
  }

  .payment-section .amount-due-input {
    width: 100%;
  }

  .form-actions {
    flex-direction: column;
    gap: 8px;
  }

  .form-actions button {
    width: 100%;
  }
}

/* Save transition state */
.form-saving {
  pointer-events: none;
  opacity: 0.7;
}