.ar-review-container {
  min-height: 100vh;
  background-color: #f5f5f5;
  padding: 2rem;
}

.ar-review-content {
  max-width: 1000px;
  margin: 0 auto;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.ar-review-header {
  border-bottom: 1px solid #e5e5e5;
  padding-bottom: 1.5rem;
  margin-bottom: 2rem;
}

.ar-review-header h1 {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ar-review-date {
  color: #666;
}

.ar-review-date p {
  margin: 0.25rem 0;
}

.ar-projects-list {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.ar-project-card {
  background-color: #f8f9fa;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  padding: 1.5rem;
}

.ar-project-title {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.ar-project-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
}

.ar-project-details p,
.ar-project-scope p {
  margin: 0.5rem 0;
}

.ar-project-payment {
  margin-top: 1.5rem;
  padding: 1rem;
  background-color: white;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
}

.ar-cod-label {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.ar-cod-checkbox {
  width: 1.25rem;
  height: 1.25rem;
}

.ar-amount-field {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.ar-amount-input {
  padding: 0.5rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  width: 120px;
}

/* New AR Notes Styles */
.ar-notes-field {
  margin-top: 1.5rem;
  width: 100%;
}

.ar-notes-field label {
  display: block;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
}

.ar-notes-input {
  width: 100%;
  min-height: 80px;
  padding: 0.75rem;
  border: 1px solid #e5e5e5;
  border-radius: 4px;
  resize: vertical;
  font-family: inherit;
  font-size: 0.95rem;
  line-height: 1.5;
  background-color: #ffffff;
  transition: border-color 0.2s ease;
}

.ar-notes-input:focus {
  outline: none;
  border-color: #0066cc;
  box-shadow: 0 0 0 2px rgba(0, 102, 204, 0.1);
}

.ar-notes-input::placeholder {
  color: #999;
}

.ar-form-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e5e5e5;
}

.ar-button {
  padding: 0.75rem 1.5rem;
  border-radius: 4px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.ar-button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.ar-button-primary {
  background-color: #0066cc;
  color: white;
  border: none;
}

.ar-button-primary:hover:not(:disabled) {
  background-color: #0052a3;
}

.ar-button-secondary {
  background-color: white;
  color: #333;
  border: 1px solid #ccc;
}

.ar-button-secondary:hover:not(:disabled) {
  background-color: #f5f5f5;
}

.ar-loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
}

.ar-loading-spinner {
  width: 2.5rem;
  height: 2.5rem;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #0066cc;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.ar-error-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 1rem;
}

.ar-error-message {
  background-color: #fff3f3;
  color: #cc0000;
  padding: 1rem;
  border-radius: 4px;
  border: 1px solid #ffcccc;
  max-width: 500px;
  width: 100%;
  text-align: center;
}