.app-header {
  background-color: #f8f8f8;
  border-bottom: 1px solid #e7e7e7;
  padding: 10px 20px;
  height: auto;
  min-height: 80px;
}

.header-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.logo-container {
  width: 150px;
  flex-shrink: 0;
}

.logo {
  max-width: 100%;
  height: auto;
  max-height: 60px;
}

.header-controls {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  align-items: center;
}

.branch-selectors {
  display: flex;
  gap: 10px;
}

.branch-select,
.sub-branch-select,
.view-mode-select {
  padding: 8px 12px;
  border-radius: 6px;
  border: 1px solid #ddd;
  background-color: white;
  font-size: 14px;
  color: #2c3e50;
  cursor: pointer;
  transition: all 0.2s ease;
}

.branch-select:hover,
.sub-branch-select:hover,
.view-mode-select:hover {
  border-color: #4CAF50;
}

.date-controls {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: white;
  padding: 5px;
  border-radius: 6px;
  border: 1px solid #ddd;
}

.today-button,
.nav-button {
  background-color: transparent;
  color: #2c3e50;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: all 0.2s ease;
}

.today-button {
  background-color: #3498db;
  color: white;
}

.today-button:hover {
  background-color: #2980b9;
}

.nav-button:hover {
  background-color: #f0f0f0;
}

.current-date {
  font-weight: 600;
  color: #2c3e50;
  padding: 0 10px;
  min-width: 200px;
  text-align: center;
  font-size: 14px;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
  .header-content {
    flex-direction: column;
    align-items: flex-start;
  }

  .logo-container {
    margin-bottom: 10px;
  }

  .header-controls {
    width: 100%;
    justify-content: space-between;
  }

  .date-controls {
    flex-grow: 1;
    justify-content: center;
  }

  .branch-selectors {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

@media (max-width: 768px) {
  .header-controls {
    flex-direction: column;
    align-items: stretch;
    gap: 10px;
  }

  .branch-select,
  .sub-branch-select,
  .view-mode-select {
    width: 100%;
  }

  .branch-selectors {
    flex-direction: column;
  }

  .date-controls {
    justify-content: space-between;
    width: 100%;
  }

  .current-date {
    min-width: 150px;
    font-size: 13px;
  }
}