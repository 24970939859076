.App {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.dashboard-content {
  display: flex;
  flex: 1;
}

main {
  flex: 1;
  padding: 20px;
}
