.sidebar {
  width: 250px;
  background-color: #f0f0f0;
  padding: 20px;
  height: calc(100vh - 80px);
  position: fixed;
  left: 0;
  top: 80px;
  overflow-y: auto;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.sidebar-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.sidebar .sidebar-content button {
  display: block;
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  background-color: #3498db;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-align: left;
  transition: background-color 0.3s ease;
}

.sidebar .sidebar-content button:hover {
  background-color: #2980b9;
}

.sidebar-spacer {
  flex-grow: 1;
}

.sidebar .sidebar-content .send-weekly-schedule {
  background-color: #3498db;
  margin-top: 10px;
}

.sidebar .sidebar-content .send-weekly-schedule:hover {
  background-color: #2980b9;
}

.sidebar .sidebar-content .send-to-ar {
  background-color: #f39c12;
  margin-top: auto;
}

.sidebar .sidebar-content .send-to-ar:hover {
  background-color: #d68910;
}

.sidebar .sidebar-content .logout-button {
  background-color: #f44336;
  color: white;
}

.sidebar .sidebar-content .logout-button:hover {
  background-color: #d32f2f;
}

/* Adjust main content area to prevent overlap with sidebar */
.dashboard-content {
  margin-left: 250px;
  padding: 20px;
  padding-top: 80px;
}

/* For smaller screens, consider making the sidebar collapsible */
@media (max-width: 768px) {
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
    top: 0;
  }
  
  .dashboard-content {
    margin-left: 0;
  }
  
  .sidebar .sidebar-content .send-weekly-schedule {
    margin-top: 20px;
  }
}